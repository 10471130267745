import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "./components/Header";
import Loading from "./components/Loading";
import "../css/style.css"
import { api, getTicketTypeById } from "ticketino-api-client";

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [eventId, setEventId] = useState("");
    const [ticketTypeId, setTicketTypeId] = useState("");
    const [promoError, setPromoError] = useState("");
    const [loading, setLoading] = useState(true);
    const [ticketTypesAvailable, setTicketTypesAvailable] = useState(true);

    const { code } = useParams();

    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        function: "",
        company: "",
        place: "",
        city: "",
        country: "176",
        email: "",
        confirmemail: "",
        promocode: code
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            if (sessionStorage.getItem("promotionCode")) {
                sessionStorage.setItem("promotionCode", "");
            }

            if (sessionStorage.getItem("promotionSuccess")) {
                sessionStorage.setItem("promotionSuccess", false);
            }

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId, res.data.eventId, res.data.ticketTypeId);
            setEventId(res.data.eventId);
            setTicketTypeId(res.data.ticketTypeId);
        });
    };

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId, ticketTypeId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order`, order);


            const ticketType = await getTicketTypeById(ticketTypeId);

            const ticketsTotal = ticketType?.ticketsTotal;
            const ticketsSold = await getNumberOfTicketsSoldOfTicketTyoe(ticketTypeId);

            const availableTickets = ticketsTotal - ticketsSold;

            sessionStorage.setItem("OrderId", response.data.id);
            setOrderId(response.data.id);
            setLoading(false);

            if (availableTickets > 0) {
               setTicketTypesAvailable(true);
            } else {
               setTicketTypesAvailable(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getNumberOfTicketsSoldOfTicketTyoe = async (ticketTypeId) => {
        try {
            const res = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/TicketsSold`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCode = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${user.promocode}`)
            .then((res) => {
                setPromoError("");
                addAddressToBasket();
            })
            .catch((error) => {
                setPromoError(
                    "Dieser Gutschein Code kann nicht auf ihre aktuelle Bestellung angewendet werden."
                );
            });
    };

    const onSubmit = async () => {
        applyPromotionCode();
    };

    const addAddressToBasket = () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            function: user.function,
            postCode: user.place,
            city: user.city,
            countryId: user.country,
            email: user.email
        };
        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                addTicketToBasket();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = [{ ticketTypeId: ticketTypeId, quantity: 1, promotionCode: user.promocode }];

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/confirmation`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onCheckedChange = (e) => {
        if (e.target.value.includes(resources.translation._Mr)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mr });
        }

        if (e.target.value.includes(resources.translation._Mrs)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mrs });
        }
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.promocode) &&
        /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.company) &&
        /^.{1,70}$/.test(user.function) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.country) &&
        /^\S+@\S+\.\S+$/.test(user.email) &&
        /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
        user.email === user.confirmemail;

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="#fff"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    {/* header */}

                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Header language={language} />
                            </div>
                        </div>
                    </div>

                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="m-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Anmeldung</h4>
                                    </div>
                                    {!ticketTypesAvailable && <div className="col-12 mt-2">
                                        <p>
                                            Wir sind ausgebucht. Alle Plätze für das EDI Podium 2024 sind bereits vergeben. Gerne setzen wir Ihren Namen auf die Warteliste und benachrichtigen Sie umgehend, sollte ein Platz frei werden. Oder möchten Sie nach der Veranstaltung die aktuellsten Informationen über das EDI Podium und die Keynotes erhalten? Dann melden Sie sich bitte direkt bei uns unter
                                            <a className="ms-1" href="mailto:marketing@medidata.ch">marketing@medidata.ch</a>.
                                        </p>
                                    </div>}
                                </div>

                                {ticketTypesAvailable && <>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <span className="gray">Anmeldecode</span><span className="red">*</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="promocode"
                                                className="form-control customTextbox"
                                                value={user.promocode}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {promoError && promoError != "" &&
                                        <div className="row">
                                            <div className="col-md-8 red">
                                                <p>{promoError}</p>
                                            </div>
                                        </div>
                                    }

                                    {/* user data */}

                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3 gray">
                                            <div className="mb-3">
                                                <span>Anrede</span><span className="red">*</span>
                                            </div>
                                            <input
                                                id="mr"
                                                type="radio"
                                                name="salutation"
                                                value={resources.translation._Mr}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="mr">Herr</label>

                                            <input
                                                id="mrs"
                                                type="radio"
                                                className="ms-4"
                                                name="salutation"
                                                value={resources.translation._Mrs}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="mrs">Frau</label>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">Vorname</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="firstname"
                                                className="form-control customTextbox"
                                                value={user.firstname}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">Nachname</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="lastname"
                                                className="form-control customTextbox"
                                                value={user.lastname}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">Firma</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="company"
                                                className="form-control customTextbox"
                                                value={user.company}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">Funktion</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="function"
                                                className="form-control customTextbox"
                                                value={user.function}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">PLZ</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="place"
                                                className="form-control customTextbox"
                                                value={user.place}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">Ort</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="text"
                                                name="city"
                                                className="form-control customTextbox"
                                                value={user.city}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">E-Mail</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control customTextbox"
                                                value={user.email}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="mb-3">
                                                <span className="gray">{resources.translation.HomeEmailConfirmation}</span><span className="red">*</span>
                                            </div>
                                            <input
                                                type="email"
                                                name="confirmemail"
                                                className="form-control customTextbox"
                                                value={user.confirmemail}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* button confirm */}

                                    <div className="row mt-5">
                                        <div className="offset-md-8 col-md-4 text-end">
                                            <button
                                                className="custom-button"
                                                onClick={onSubmit}
                                                disabled={!validation}
                                            >
                                                {resources.translation.HomeNext}
                                            </button>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <p className="mt-5 text-center">
                                {resources.translation && (
                                    <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                )}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
