import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Header from "./components/Header";
import pdfImage from "../img/pdf-icon.png";

const Step10_Confirmation = () => {
    const [resources, setResources] = useState({});
    const [orderId] = useState(
        sessionStorage.getItem("OrderId")
    );
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");

    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();
        requestFormSettings();
    }, [language]);

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            confirmOrder();
        });
    };

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    // confirm the order
    const confirmOrder = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
            .then((res) => {
                setOrder(res.data);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.log(error.response.data));
    };

    return (
        <div>
            {/* header */}
            <div className="container wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Header language={language}/>
                    </div>
                </div>
            </div>

            {resources.translation && (
                <div className="container wrapper">
                    <div className="m-5">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <h1>
                                    Vielen Dank für Ihre Anmeldung.
                                </h1>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <p>Ihre Bestell-Nr. lautet {order.invoiceNumber && order.invoiceNumber}</p>
                                    <p>
                                        Bitte notieren Sie Ihre Bestell-Nr. für allfällige
                                        Support-Anfragen. <br></br>
                                        Sie erhalten in Kurze eine Bestellbestätigung per E-Mail.
                                    </p>
                                    <p>
                                        Laden Sie jetzt Ihr Ticket herunter und drucken Sie es aus.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8 mt-4">
                                    <img src={pdfImage} className="pdf-icon" alt="car picture"></img>
                                    <button
                                        className="custom-button form-control pdf-button"
                                        onClick={() => getPdfTicketsOfOrder()}
                                    >
                                        TICKET HERUNTERLADEN
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-5 text-center">
                        {resources.translation && (
                            <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};

export default Step10_Confirmation;
