import axios from "axios";
import React, { useEffect, useState } from "react";
import imageHeader from "../../img/240205_E_Banner_Buchungsmaske_Ticketino_595x73px.jpg";

const Header = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className="fill container">
            <img src={imageHeader} className="img-fluid" alt="car picture"></img>
        </div>
    );
};

export default Header;
